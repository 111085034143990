import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d880cbe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container d-f flex-d-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb, { dataSource: [] }),
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onTabClick: _ctx.tabClick
    }, {
      default: _withCtx(() => [
        (_ctx.$btnPermissions('MPA0001', '/paymentApproveMgmt'))
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "1",
              tab: _ctx.tabNamePending
            }, null, 8, ["tab"]))
          : _createCommentVNode("", true),
        (_ctx.$btnPermissions('MPA0002', '/paymentApproveMgmt'))
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "2",
              tab: "Payment Tracking",
              "force-render": ""
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["activeKey", "onTabClick"]),
    (_ctx.showRouterView)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}