
import { defineComponent, ref, computed } from "vue";
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import { btnPermissions } from "@/utils";

interface TabMenuPermission {
  path: string;
  privcd: string;
}

export default defineComponent({
  name: 'paymentApproveMgmt',
  props: {},
  setup() {
    const activeKey = ref<string>('1')

    const route = useRoute()
    if (route.name === 'Payment Tracking') {
      activeKey.value = '2'
    } else {
      activeKey.value = '1'
    }

    const {state} = useStore()
    const tabNamePending = computed(()=>{
      return "Pending Approval ("+state.payment.approvealTableCount+")"
    })

    const router = useRouter()

    // 点击tab
    const tabClick = (tab: string) => {
      switch (tab) {
        case '1':
          router.push({path: '/paymentApproveMgmt'})
          break
        case '2':
          router.push({path: '/paymentApproveMgmt/paymentTracking'})
          break
      }
    }

    const showRouterView = computed(() => {
      switch (route.name) {
        case 'paymentApproveMgmt':
          return btnPermissions('MPA0001', '/paymentApproveMgmt')
        case 'Payment Tracking':
          return btnPermissions('MPA0002', '/paymentApproveMgmt')
        default:
          return true
      }
    })

    return {
      activeKey,
      tabNamePending,
      tabClick,
      showRouterView
    }
  }
})
